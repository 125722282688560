import moment from 'moment'
import Const from '../constants'
import axiosApiInstance from '../interceptors'

class StoreInfoService {
  getStoreInfo() {
    return axiosApiInstance.get(Const.api_url + 'dashboard/store-info')
  }

  getDeliveryTimezone(store_uuid: any) {
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/delivery_timezone')
  }

  getDeliveryTimezoneChange(order_id: any) {
    return axiosApiInstance.get(Const.api_url + 'store/order/' + order_id + '/delivery_services')
  }

  getInfoDeliveryTimezone(id: any) {
    return axiosApiInstance.get(Const.api_url + 'delivery_timezone/' + id)
  }

  getRegularOrder() {
    return axiosApiInstance.get(Const.api_url + 'member/regular_order')
  }

  getRegularOrderId(id: any): Promise<any> {
    return axiosApiInstance.get(Const.api_url + 'member/regular_order/' + id + '/detail')
  }

  getValidateDeadline(service_id: any, dateDelivery: any): Promise<any> {
    return axiosApiInstance.get(Const.api_url + 'validate-deadline/' + service_id + '?date=' + dateDelivery)
  }

  updateRegularOrderId(id: any, delivery_date: any, delivery_service_id: any, regular_type: any, products: any) {
    let order_detail: any = {
      order_date: moment(new Date()).format('yyyy-MM-DD'),
      delivery_date: delivery_date,
      delivery_service_id: delivery_service_id,
      regular_type: regular_type,
      products: products
    }
    return axiosApiInstance.put(Const.api_url + 'member/regular_order/' + id + '/detail', order_detail)
  }

  createOrder(order_detail: any) {
    return axiosApiInstance.post(Const.api_url + 'member/regular_order/create',
      order_detail)
  }

  getLeafletsStore(): Promise<any> {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/leaflets')
  }

}

export default new StoreInfoService()
