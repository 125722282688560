import Const from '../constants'
import axiosApiInstance from '../interceptors'

class NoticeService {
  getNotice() {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/notifications')
  }
}

export default new NoticeService()
