import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { CartProduct } from '../../models/CartProduct'

@Module({ namespaced: true })
class Product extends VuexModule {
  public productDetail: CartProduct[] = []

  @Mutation
  public addProductPreviewMutation(payload: CartProduct): void {
    if (this.productDetail.length == 0) {
      this.productDetail.push(payload)
    }
  }

  @Action({ rawError: true })
  addProductPreview(data: CartProduct) {
    this.context.commit('addProductPreviewMutation', JSON.parse(JSON.stringify(data)))
  }

  get getProductSearch(): CartProduct[] {
    return this.productDetail
  }
}

export default Product
