import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class StoreZipcode extends VuexModule {
  public zipcode: string = ''

  @Mutation
  updateZipcode(payload?: any): void {
    this.zipcode = payload
  }

  @Action({ rawError: true })
  SET_NEW_ZIPCODE(payload?: any): void {
    this.context.commit('updateZipcode', JSON.parse(JSON.stringify(payload)));
  }

  get getZipcode(): string {
    return this.zipcode
  }

}

export default StoreZipcode
