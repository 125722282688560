import Const from '../constants'
import axiosApiInstance from '../interceptors'

class OrderService {
  createNormalOrder(data: any) {
    return axiosApiInstance.post(Const.api_url + 'order', data)
  }

  updateNormalOrder(data: any, order_id: any) {
    return axiosApiInstance.put(Const.api_url + 'order/' + order_id, data)
  }

  getOrderMergeAble() {
    return axiosApiInstance.get(Const.api_url + 'member/mergeable-order')
  }

  getOrderMergeSelect(order_id: number) {
    return axiosApiInstance.get(Const.api_url + 'order/' + order_id)
  }

  getOrderEdit(order_id: number) {
    return axiosApiInstance.get(Const.api_url + 'order/' + order_id)
  }

  getOrderMerge(order_id: number) {
    return axiosApiInstance.get(Const.api_url + 'order/' + order_id)
  }

  cancelOrder(order_id: number) {
    return axiosApiInstance.put(Const.api_url + 'order/cancel/' + order_id)
  }
}

export default new OrderService()