import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class Category extends VuexModule {
  public category: any[] = []

  @Mutation
  public addCategoryMutation(payload: any): void {
    if (this.category.length == 0) {
      this.category = payload
    }
  }

  @Action({ rawError: true })
  SET_CATEGORY(data: any) {
    this.context.commit('addCategoryMutation', data)
  }

  @Mutation
  public clearCategoryMutation(): void {
    if (this.category.length !== 0) {
      this.category = []
    }
  }

  @Action({ rawError: true })
  CLEAR_CATEGORY() {
    this.context.commit('clearCategoryMutation')
  }

  get getCategory(): any[] {
    return this.category
  }
}

export default Category
