





























import { Component, Vue } from 'vue-property-decorator'
import StoreInfoService from '@/services/StoreInfoService'

@Component
export default class DeliveryTimeTable extends Vue {
  public store_uuid: any
  public store_name: any = ''
  public itemsDeliveryTimezone: any = []
  public message: boolean = true

  created() {
    let tour_mode = localStorage.getItem('tour_mode') ? true : false
    if (!tour_mode) {
      StoreInfoService.getStoreInfo().then(response => {
        if (response) {
          let items = response.data.items
          this.store_name = items.name
          this.store_uuid = items.uuid
          StoreInfoService.getDeliveryTimezone(this.store_uuid).then(
            response => {
              if (response) {
                this.itemsDeliveryTimezone = response.data.items.display_service
              }
            },
            (error: any) => {
              if (error) {
                if (error.response.status === 429) {
                  localStorage.clear()
                  this.$bvModal.show(`too-many-request`)
                }
                let errorData = error.response.data
                this.message = errorData.message[0].store_uuid[0]
              }
            }
          )
        }
      })
    }
  }
}
