import Const from '../constants'
import axiosApiInstance from '../interceptors'

class BannerStoreService {
  getBannerStore() {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/banners')
  }
}

export default new BannerStoreService()
