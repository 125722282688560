import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

@Module({ namespaced: true })
class ChangeProfile extends VuexModule {

  @Mutation
  public changeProfileSuccess(data: any): void {
    store.state.Auth.user = data
  }

  @Action({ rawError: true })
    
  CHANGE_PROFILE(data: any) {
    this.context.commit('changeProfileSuccess', data)
  }
}

export default ChangeProfile
