




























import { Component, Vue } from 'vue-property-decorator'
import BannerStoreService from '@/services/BannerStoreService'

@Component({})
export default class BannerSlice extends Vue {
  public isShowBanner: boolean = false
  public swiperBannerHomeOption = {
    slidesPerView: 3.2,
    spaceBetween: 12,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }

  private banners: any = {}

  created() {
    if (localStorage.getItem('store_uuid')) {
      BannerStoreService.getBannerStore().then(
        (response: any) => {
          this.banners = response.data.items
          if (this.banners.length === 0) {
            this.isShowBanner = false
          } else {
            this.isShowBanner = true
          }
        },
        (error: any) => {
          if (error.response.status === 429) {
            this.manyRequest()
          }
        }
      )
    }
  }

  private manyRequest() {
    localStorage.clear()
    this.$bvModal.show(`too-many-request`)
  }

  public openWindow(target_url: any) {
    window.open(target_url)
  }
}
