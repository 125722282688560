





























import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const Product = namespace('Product')

@Component
export default class SearchHeader extends Vue {
  private searchValue: string = ''
  private currentUrl: any = ''
  @Product.Getter
  private getProductSearch!: string
  @Product.Action
  private SET_NEW_PRODUCT_SEARCH!: (data: string) => void

  @Watch('$route', { deep: true, immediate: true })
  watchRouterUrl() {
    this.currentUrl = this.$route
    if (this.currentUrl.name !== 'product-search') {
      // localStorage.removeItem('product_search')
      this.SET_NEW_PRODUCT_SEARCH(this.searchValue)
    }
  }

  created() {
    if (this.currentUrl.name !== 'product-search') {
      // localStorage.removeItem('product_search')
      this.SET_NEW_PRODUCT_SEARCH(this.searchValue)
    } else {
      this.searchValue = this.getProductSearch
    }
  }

  public handleChangeSearchValue() {
    localStorage.setItem('product_search', JSON.stringify(this.searchValue))
    this.SET_NEW_PRODUCT_SEARCH(this.searchValue)
  }

  public search() {
    this.$router
      .push({
        name: 'product-search'
      })
      .catch(() => {})
  }
}
