import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class CategorySidebar extends VuexModule {
  public viewCategoryOnly: boolean = false

  @Mutation
  public activeViewCategoryOnly(payload: any): void {
    this.viewCategoryOnly = payload
  }

  @Action({ rawError: true })
  ACTIVE_VIEW_CATEGORY_ONLY(payload: any) {
    this.context.commit('activeViewCategoryOnly', payload)
  }

  @Mutation
  public disableViewCategoryOnly(payload: any): void {
    this.viewCategoryOnly = payload
  }

  @Action({ rawError: true })
  DISABLE_VIEW_CATEGORY_ONLY(payload: any) {
    this.context.commit('disableViewCategoryOnly', payload)
  }

  get getModeViewCategory() {
    return this.viewCategoryOnly
  }
}

export default CategorySidebar
