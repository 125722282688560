




















































import { Component, Vue } from 'vue-property-decorator'
import MemberLoginHeader from './MemberLoginHeader.vue'
import CategoryService from '@/services/CategoryService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')
const Category = namespace('Category')

@Component({
  components: {
    MemberLoginHeader
  }
})
export default class SidebarPc extends Vue {
  @Auth.Getter
  public isLoggedIn!: boolean
  @Auth.State('user')
  public currentUser!: any
  private category = ''
  public selectedStore: any = false

  @Auth.Action
  private signOut!: () => void
  @Category.Action
  private SET_CATEGORY!: (data: any) => any
  @Category.Getter
  private getCategory!: any

  created() {
    this.selectedStore = localStorage.getItem('store_uuid') ? true : false
    if (localStorage.getItem('store_uuid')) {
      if (this.getCategory.length !== 0) {
        this.category = this.getCategory
      } else {
        CategoryService.getCategory().then(
          (response: { data: { items: string } }) => {
            this.category = response.data.items
            this.SET_CATEGORY(this.category)
          },
          (error: {
            response: { data: any; status: any }
            message: any
            toString: () => any
          }) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
            this.category =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          }
        )
      }
    }
  }

  private goToLogin() {
    localStorage.clear()
    this.$router.push({ name: 'login' })
  }

  public logout() {
    this.signOut()
  }

  private goToCart() {
    this.$router.push({ name: 'cart' }).catch(() => {})
  }

  public goToHome() {
    this.$router.push({ name: 'home' }).catch(() => {})
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}
