












import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const CategorySidebar = namespace('CategorySidebar')

@Component
export default class BtnCategory extends Vue {
  @CategorySidebar.Action
  private ACTIVE_VIEW_CATEGORY_ONLY!: (data: any) => any

  @CategorySidebar.Getter
  public getModeViewCategory!: boolean

  public async showSideBarCategoryOnly() {
    await this.ACTIVE_VIEW_CATEGORY_ONLY(true)
  }
}
