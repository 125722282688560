import Const from '../constants'
import axiosApiInstance from '../interceptors'

class LogoService {
  getAvatar() {
    return axiosApiInstance.get(Const.api_url + 'store-image')
  }
}

export default new LogoService()
