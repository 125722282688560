











































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import BtnTimezoneDelivery from '@/components/BtnTimezoneDelivery.vue'
import BtnCategory from '@/components/BtnCategory.vue'
import RecurringPurchasesNotice from '@/components/RecurringPurchasesNotice.vue'
import SearchHeader from '@/components/SearchHeader.vue'
import DeliveryService from '@/components/DeliveryService.vue'
import BarCodeScanButton from '@/components/BarCodeScanButton.vue'
import Cart from '@/components/Cart.vue'
import ProductRecommend from '@/components/ProductRecommend.vue'
import PopularProduct from '@/components/ProductRemind.vue'
import CategoryMenu from '@/components/CategoryMenu.vue'
import SidebarPc from '@/components/SidebarPc.vue'
import Footer from '@/components/Footer.vue'
import ModalAddToCartTourMode from '@/components/ModalAddToCartTourMode.vue'
import { namespace } from 'vuex-class'
import LayoutSettingService from '@/services/LayoutSettingService'
import Banner from '@/components/Banner.vue'
import BannerSlice from '@/components/BannerSlice.vue'
import ProductFavourite from '@/views/ProductFavouriteComponent.vue'
import PurchaseHistory from '@/views/PurchaseHistoryComponent.vue'
import Notice from '@/components/Notice.vue'
import InnerStoreSearch from '@/components/InnerStoreSearch.vue'
import DeliveryTimeTable from '@/components/DeliveryTimeTable.vue'
const Auth = namespace('Auth')

@Component({
  components: {
    Header,
    BtnTimezoneDelivery,
    BtnCategory,
    RecurringPurchasesNotice,
    SearchHeader,
    DeliveryService,
    BarCodeScanButton,
    CategoryMenu,
    Cart,
    ProductRecommend,
    PopularProduct,
    SidebarPc,
    Footer,
    Notice,
    InnerStoreSearch,
    DeliveryTimeTable,
    Banner,
    ProductFavourite,
    PurchaseHistory,
    BannerSlice,
    ModalAddToCartTourMode
  }
})
export default class LayoutDefault extends Vue {
  @Auth.Getter
  private isLoggedIn!: boolean
  public layoutSettings: LayoutSetting[] = []

  created() {
    if (!localStorage.getItem('store_uuid')) {
      this.$router.push({ name: 'store-first' })
    }
    LayoutSettingService.getSetting().then((res: any) => {
      if (res) {
        this.layoutSettings = res.data.items
        if (this.$route.path === '/purchase-history') {
          this.layoutSettings = this.layoutSettings.filter(
            (el: any) => el.component_name !== 'purchase-history'
          )
        }
        if (this.$route.path === '/product-favourite') {
          this.layoutSettings = this.layoutSettings.filter(
            (el: any) => el.component_name !== 'product-favourite'
          )
        }
        if (this.$route.path === '/suggestion') {
          this.layoutSettings = this.layoutSettings.filter(
            (el: any) => el.component_name !== 'popular-product'
          )
          this.layoutSettings = this.layoutSettings.filter(
            (el: any) => el.component_name !== 'product-recommend'
          )
        }
      }
    })
  }

  public getComponentName(setting: LayoutSetting) {
    if (!setting.auth_req) {
      return setting.component_name
    } else {
      return this.isLoggedIn ? setting.component_name : ''
    }
  }
}
