import Const from '../constants'
import axiosApiInstance from '../interceptors'

class ProductService {
  getProduct(mode: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/products' + '?mode=' + mode)
  }

  getProductByCategory(category_id: string, mode: number, sort: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'category/' + category_id + '/products?store_uuid=' + store_uuid + '&mode=' + mode + '&sort=' + sort)
  }

  getProductBySearch(product_search: string, mode: number, sort: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/products-search?key_word=' + product_search + '&mode=' + mode + '&sort=' + sort)
  }

  getProductRecommend(mode: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/products-topfive' + '?mode=' + mode)
  }

  getProductRemind(mode: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/products-topten' + '?mode=' + mode)
  }

  getProductDetail(product_id: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    let tour_mode = localStorage.getItem('tour_mode')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/products/' + product_id + '?tour_mode=' + tour_mode)
  }

  getProductQuantity(product_id: number) {
    return axiosApiInstance.get(Const.api_url + 'products/' + product_id + '/quantity')
  }

  getProductFavorite(mode: number) {
    return axiosApiInstance.get(Const.api_url + 'member/favourite-product?mode=' + mode)
  }

  getProductBarCode(barcode: any, mode: number) {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/products-barcode?barcode=' + barcode + '&mode=' + mode)
  }

  addProductFavorite(product_id: number) {
    let data = {
      product_id: product_id
    }
    return axiosApiInstance.post(Const.api_url + 'member/favourite-product', data)
  }

  removeProductFavorite(product_id: number) {
    let data = {
      product_id: product_id
    }
    return axiosApiInstance.post(Const.api_url + 'member/remove-favourite-product', data)
  }
}

export default new ProductService()
