import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class Notification extends VuexModule {
  public notification: any[] = []

  @Mutation
  public addNotificationMutation(payload: any): void {
    if (this.notification.length === 0) {
      this.notification = payload
    }
  }

  @Action({ rawError: true })
  SET_NOTIFICATION(data: any) {
    this.context.commit('addNotificationMutation', data)
  }

  @Mutation
  public clearNotificationMutation(): void {
    if (this.notification.length !== 0) {
      this.notification = []
    }
  }

  @Action({ rawError: true })
  CLEAR_NOTIFICATION(data: any) {
    this.context.commit('clearNotificationMutation')
  }

  get getNotification(): any[] {
    return this.notification
  }
}

export default Notification
