


































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const ModeBuyProduct = namespace('ModeBuyProduct')
const CartProductStore = namespace('CartProductStore')

@Component({
  components: {}
})
export default class RecurringPurchasesNotice extends Vue {
  public showNotice: boolean = false
  public mode: number = 1

  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number
  @ModeBuyProduct.Action
  private SET_NEW_MODE_BUY_PRODUCT!: (data: number) => void
  @CartProductStore.Action
  private CLEAR_CART_PRODUCT!: () => void

  created() {
    if (this.getModeBuyProduct === 2) {
      this.showNotice = true
    } else {
      this.showNotice = false
    }
  }

  public hideModalRecurringPurchasesNotice() {
    // eslint-disable-next-line no-extra-semi
    ;(this.$refs['modal-recurring-purchases-notice'] as any).hide()
  }

  public confirmSwitchMode() {
    window.location.reload()
    this.showNotice = false
    this.CLEAR_CART_PRODUCT()
    this.SET_NEW_MODE_BUY_PRODUCT(1)
    this.hideModalRecurringPurchasesNotice()
    localStorage.removeItem('display_over_view')
    localStorage.removeItem('go_to_subscription_setting-detail')
  }
}
