import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './modules/auth.module'
import Category from './modules/category.module'
import Product from './modules/product.module'
import StoreZipcode from './modules/store-zipcode.module'
import CartProductStore from './modules/cart-product-store.module'
import CartProductStoreEdit from './modules/cart-product-store-edit.module'
import ProductDetailStore from './modules/product-detail.module'
import ModeBuyProduct from './modules/mode-buy-product.module'
import OrderPay from './modules/order-pay.module'
import EditOrderSetting from './modules/edit-setting-order.module'
import ChangeProfile from './modules/change-profile.module'
import Notification from './modules/notification.module'
import ModeCartMerge from './modules/mode-cart-merge.module'
import ModeCartEdit from './modules/mode-cart-edit.module'
import CategorySidebar from './modules/category-sidebar.modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
    Category,
    Product,
    StoreZipcode,
    CartProductStore,
    CartProductStoreEdit,
    ProductDetailStore,
    ModeBuyProduct,
    OrderPay,
    EditOrderSetting,
    ChangeProfile,
    Notification,
    ModeCartMerge,
    ModeCartEdit,
    CategorySidebar
  }
})
