

























































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')

@Component({
  components: {}
})
export default class MessageRequest extends Vue {
  @Auth.Action
  private signOut!: () => void

  public backToLogin() {
    this.$bvModal.hide('too-many-request')
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('cart_product')
    localStorage.removeItem('product_search')
    localStorage.removeItem('modeBuySelect')
    localStorage.removeItem('mode_purchases')
    localStorage.removeItem('orderId')
    localStorage.removeItem('store_uuid')
    localStorage.removeItem('go_to_subscription_setting-detail')
    localStorage.removeItem('display_over_view')
    localStorage.removeItem('items-detail')
    localStorage.removeItem('member')
    localStorage.removeItem('store_id')
    localStorage.removeItem('items')
    localStorage.removeItem('user')
    localStorage.removeItem('category')
    localStorage.removeItem('delivery_service_id_normal')
    localStorage.removeItem('delivery_service_id_periodic')
    localStorage.removeItem('delivery_date_periodic')
    localStorage.removeItem('delivery_date_normal')
    localStorage.removeItem('store_id')
    localStorage.removeItem('member')
    this.signOut()
    this.$router.push({ name: 'login' })
  }
}
