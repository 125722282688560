import Const from '../constants'
import axiosApiInstance from '../interceptors'

class PurchaseService {
  getPurchase(mode_purchases: any) {
    return axiosApiInstance.get(Const.api_url + 'member/purchase-history?mode=' + mode_purchases)
  }

  getHistoryPurchase() {
    return axiosApiInstance.get(Const.api_url + 'member/purchase-history-month')
  }
}

export default new PurchaseService()
