import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { CartProduct } from '../../models/CartProduct'

@Module({ namespaced: true })
class CartProductStore extends VuexModule {
  public cartInitState: CartProduct[] = localStorage.getItem('cart_product') ? JSON.parse(localStorage.getItem('cart_product') || '') : []

  @Mutation
  public addSingleProduct(payload: CartProduct): void {
    if (this.cartInitState.length > 0) {
      const index = this.cartInitState.findIndex((item) => item.id === payload.id)
      if (this.cartInitState[index]) {
        this.cartInitState[index].quantity++
        this.cartInitState[index].skip = 0
        localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
        this.cartInitState = JSON.parse(localStorage.getItem('cart_product') || '')
        return
      }
    }

    this.cartInitState.push(payload)
    localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
    this.cartInitState = JSON.parse(localStorage.getItem('cart_product') || '')
  }

  @Mutation
  public addMultiProduct(payload: CartProduct): void {
    if (this.cartInitState.length > 0) {
      const index = this.cartInitState.findIndex((item) => item.id === payload.id)
      if (this.cartInitState[index]) {
        this.cartInitState[index].quantity += payload.quantity
        this.cartInitState[index].skip = 0
        localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
        this.cartInitState = JSON.parse(localStorage.getItem('cart_product') || '')
        return
      }
    }

    this.cartInitState.push(payload)
    localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
    this.cartInitState = JSON.parse(localStorage.getItem('cart_product') || '')
  }

  @Mutation
  public deleteSingleProduct(product_id: number): void {
    const productIndexDelete = this.cartInitState.findIndex((item) => item.id === product_id)
    this.cartInitState.splice(productIndexDelete, 1)
    localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
    this.cartInitState = localStorage.getItem('cart_product') ? JSON.parse(localStorage.getItem('cart_product') || '') : []
  }

  @Mutation
  public deleteAllProduct(): void {
    const modeBuyProduct = Number(localStorage.getItem('mode_purchases'))
    let cartFilterByMode = this.cartInitState.filter((element: any) => element.mode !== modeBuyProduct)
    localStorage.setItem('cart_product', JSON.stringify(cartFilterByMode))
    this.cartInitState = localStorage.getItem('cart_product') ? JSON.parse(localStorage.getItem('cart_product') || '') : []
  }

  @Mutation
  public updateSingleProduct(data: any): void {
    const productIndexUpdate = this.cartInitState.findIndex((item) => item.id === data.id)
    if (this.cartInitState[productIndexUpdate]) {
      this.cartInitState[productIndexUpdate].quantity = data.quantity

    }
    localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
    this.cartInitState = JSON.parse(localStorage.getItem('cart_product') || '')
  }

  @Mutation
  public clearCartProduct() {
    this.cartInitState = []
    localStorage.setItem('cart_product', JSON.stringify(this.cartInitState))
  }

  @Action({ rawError: true })
  public CLEAR_CART_PRODUCT() {
    this.context.commit('clearCartProduct')
  }

  @Action({ rawError: true })
  addToCart(data: CartProduct) {
    this.context.commit('addSingleProduct', JSON.parse(JSON.stringify(data)))
  }

  @Action({ rawError: true })
  addMultiToCart(data: CartProduct) {
    this.context.commit('addMultiProduct', JSON.parse(JSON.stringify(data)))
  }

  @Action({ rawError: true })
  removeFromCart(productIdDelete: string) {
    this.context.commit('deleteSingleProduct', productIdDelete)
  }

  @Action({ rawError: true })
  removeAllCart() {
    this.context.commit('deleteAllProduct', null)
  }

  @Action({ rawError: true })
  updateFromCart(data: any) {
    this.context.commit('updateSingleProduct', data)
  }

  @Mutation
  public setDataProducts(data: any): void {
    localStorage.setItem('cart_product', JSON.stringify(data))
    this.cartInitState = localStorage.getItem('cart_product') ? JSON.parse(localStorage.getItem('cart_product') || '') : []
  }

  @Action({ rawError: true })
  public SET_DATA_PRODUCTS(data: any): void {
    this.context.commit('setDataProducts', data)
  }

  get getCart(): CartProduct[] {
    return this.cartInitState
  }

}

export default CartProductStore