

















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ProductService from '@/services/ProductService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')
const CartProductStore = namespace('CartProductStore')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component
export default class ProductRemind extends Vue {
  public swiperOption = {
    slidesPerView: 1,
    spaceBetween: 8,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      992: {
        slidesPerView: 5,
        spaceBetween: 15
      },
      1280: {
        slidesPerView: 5.1,
        spaceBetween: 20
      },
      1440: {
        slidesPerView: 5.1,
        spaceBetween: 25
      },
      1660: {
        slidesPerView: 5,
        spaceBetween: 30
      }
    }
  }
  public productAddToCard = ''
  public imgProductPreview: any = []
  @Auth.Getter
  public isLoggedIn!: boolean
  public isLoadingProductRemind: boolean = false

  private products: any = []
  private stockQuantity: any = 0
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @CartProductStore.Action
  private addToCart!: (data: any) => any
  @CartProductStoreEdit.Action
  private addToCartEdit!: (data: any) => any
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number

  @Watch('getModeBuyProduct')
  onModeBuyProductChanged() {
    if (localStorage.getItem('store_uuid')) {
      ProductService.getProductRemind(this.getModeBuyProduct).then(
        (response: any) => {
          this.products = response.data.items
          this.imgProductPreview = this.products.images
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          this.products =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    }
  }

  created() {
    this.fetchProductRemind()
  }

  public async fetchProductRemind() {
    this.isLoadingProductRemind = true
    await ProductService.getProductRemind(this.getModeBuyProduct).then(
      (response: any) => {
        this.products = response.data.items
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        this.products =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      }
    )
    this.isLoadingProductRemind = false
  }

  public linkToProductDetail(productItem: any) {
    this.$router
      .push({
        name: 'product-detail-preview',
        query: { id: productItem.id }
      })
      .catch(() => {})
  }

  public async addToCartView(product: any) {
    let tour_mode =
      localStorage.getItem('tour_mode') &&
      localStorage.getItem('tour_mode') === 'on'
        ? true
        : false
    if (tour_mode || this.isLoggedIn === false) {
      this.$bvModal.show('modal-add-to-cart-redirect-tour-mode')
    } else {
      await ProductService.getProductQuantity(product.id).then(
        (response: any) => {
          this.stockQuantity = response.data.items
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        }
      )
      let productToAdd = {
        id: product.id,
        name: product.product_name,
        product_name_display: product.product_name_display,
        img_url: product.images.length > 0 ? product.images[0].path : null,
        stock_quantity: this.stockQuantity,
        quantity: 1,
        normal_price_excluding_tax: product.normal_price_excluding_tax,
        normal_price_including_tax: product.normal_price_including_tax,
        note: product.note,
        mode: this.getModeBuyProduct,
        skip: 0,
        tax_classification: product.tax_classification,
        temperature_control_name: product.temperature_control.name,
        temperature_control_price: product.temperature_control.price,
        temperature_control_id: product.temperature_control.id
      }
      let itemInCart = []
      if (localStorage.getItem('order_id_edit')) {
        itemInCart = this.getCartEdit.find((e: any) => e.id === product.id)
      } else {
        itemInCart = this.getCart.find((e: any) => e.id === product.id)
      }
      if (itemInCart && itemInCart.quantity > 99) {
        this.$toast(`一度に注文出来る最大個数は「100」`, {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false
        })
      } else {
        if (this.stockQuantity === 'unlimited') {
          this.checkAddOrder(productToAdd)
        } else {
          if (this.stockQuantity > 0) {
            let cartLength = 0
            let item = []
            if (localStorage.getItem('order_id_edit')) {
              cartLength = this.getCartEdit.length
              item = this.getCartEdit.find((e: any) => e.id === product.id)
            } else {
              cartLength = this.getCart.length
              item = this.getCart.find((e: any) => e.id === product.id)
            }
            if (cartLength > 0) {
              if (item) {
                if (item.quantity < this.stockQuantity) {
                  this.checkAddOrder(productToAdd)
                } else {
                  if (this.stockQuantity === 0 || this.stockQuantity === null) {
                    this.$toast(`この商品は在庫がありません。`, {
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: false,
                      rtl: false
                    })
                  } else {
                    this.$toast(
                      `この商品は` +
                        product.stock_quantity +
                        `個のみが残ります。`,
                      {
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: false,
                        rtl: false
                      }
                    )
                  }
                }
              } else {
                this.checkAddOrder(productToAdd)
              }
            } else {
              this.checkAddOrder(productToAdd)
            }
          } else {
            if (this.stockQuantity === 0 || this.stockQuantity === null) {
              this.$toast(`この商品は在庫がありません。`, {
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: false,
                rtl: false
              })
            } else {
              this.$toast(
                `この商品は` + this.stockQuantity + `個のみが残ります。`,
                {
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: false,
                  rtl: false
                }
              )
            }
          }
        }
      }
    }
  }

  private checkAddOrder(productToAdd: any) {
    if (localStorage.getItem('order_id_edit')) {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCartEdit(productToAdd)
    } else {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCart(productToAdd)
    }
  }
}
