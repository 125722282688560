var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"stageParent",staticClass:"stage-parent",attrs:{"id":"stage-parent"}},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.configKonva}},[_c('v-layer',[_c('v-image',{attrs:{"config":{
          image: _vm.flyerImage,
          width: _vm.stageWidth * _vm.scale,
          height: _vm.stageHeight * _vm.scale
        }}})],1),_vm._l((_vm.layerList),function(layerItem){return _c('v-layer',{key:layerItem.id},[_c('div',{attrs:{"cursor":"pointer"}}),_c('v-rect',{attrs:{"config":{
          x: layerItem.x * _vm.scale,
          y: layerItem.y * _vm.scale,
          width: layerItem.width * _vm.scale,
          height: layerItem.height * _vm.scale
        }},on:{"touchstart":function($event){return _vm.handleClickLayer(layerItem)},"click":function($event){return _vm.handleClickLayer(layerItem)},"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }