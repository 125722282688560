

















import { Component, Vue } from 'vue-property-decorator'
import CheckDevice from '../helpers/CheckDevice'

@Component
export default class BarCodeScanButton extends Vue {
  public isMobile: boolean = false

  created() {
    if (CheckDevice.isMobile()) {
      this.isMobile = true
    }
  }
}
