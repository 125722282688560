





















































































































import { Component, Vue } from 'vue-property-decorator'
import ProductRecommend from '@/components/ProductRecommend.vue'
import ProductRemind from '@/components/ProductRemind.vue'
import Cart from '@/components/Cart.vue'
import CategoryMenu from '@/components/CategoryMenu.vue'
import ProductService from '@/services/ProductService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')
const CartProductStore = namespace('CartProductStore')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  components: {
    ProductRecommend,
    ProductRemind,
    CategoryMenu,
    Cart
  }
})
export default class ProductList extends Vue {
  public hasProductList: boolean = false
  public imgProductPreview: any = []
  public activeModalAddToCart: boolean = false

  private rows: number = 0
  private currentPage: number = 1
  private perPage: number = 10
  private productFavourite: any = []
  private stockQuantity: any = 0

  @Auth.Getter
  public isLoggedIn!: boolean
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @CartProductStore.Action
  private addToCart!: (data: any) => any
  @CartProductStoreEdit.Action
  private addToCartEdit!: (data: any) => any
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number

  created() {
    this.fetchData()
  }

  public fetchData() {
    this.hasProductList = false
    let tour_mode = localStorage.getItem('tour_mode') ? true : false
    if (!tour_mode) {
      ProductService.getProductFavorite(this.getModeBuyProduct).then(
        (response: any) => {
          this.productFavourite = response.data.items
          this.imgProductPreview = this.productFavourite.images
          this.rows = this.productFavourite.length
          if (this.rows > 0) {
            this.hasProductList = true
          }
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          this.productFavourite =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    }
  }

  get productFavouriteListToPaginate() {
    if (this.productFavourite.length > 0) {
      return this.productFavourite.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    } else {
      return null
    }
  }

  public linkToProductDetail(productItem: any) {
    this.$router.push({
      name: 'product-detail-preview',
      query: { id: productItem.id }
    })
  }

  public async addToCartView(product: any) {
    let tour_mode =
      localStorage.getItem('tour_mode') &&
      localStorage.getItem('tour_mode') === 'on'
        ? true
        : false
    if (tour_mode || this.isLoggedIn === false) {
      this.$bvModal.show('modal-add-to-cart-redirect-tour-mode')
    } else {
      await ProductService.getProductQuantity(product.id).then(
        (response: any) => {
          this.stockQuantity = response.data.items
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        }
      )
      let productToAdd = {
        id: product.id,
        name: product.product_name,
        product_name_display: product.product_name_display,
        img_url: product.images.length > 0 ? product.images[0].path : null,
        stock_quantity: this.stockQuantity,
        quantity: 1,
        normal_price_excluding_tax: product.normal_price_excluding_tax,
        normal_price_including_tax: product.normal_price_including_tax,
        note: product.note,
        mode: this.getModeBuyProduct,
        skip: 0,
        tax_classification: product.tax_classification,
        temperature_control_name: product.temperature_control.name,
        temperature_control_price: product.temperature_control.price,
        temperature_control_id: product.temperature_control.id
      }
      this.activeModalAddToCart = false
      let itemInCart = []
      if (localStorage.getItem('order_id_edit')) {
        itemInCart = this.getCartEdit.find((e: any) => e.id === product.id)
      } else {
        itemInCart = this.getCart.find((e: any) => e.id === product.id)
      }
      if (itemInCart && itemInCart.quantity > 99) {
        this.$toast(`一度に注文出来る最大個数は「100」`, {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false
        })
      } else {
        if (this.stockQuantity === 'unlimited') {
          this.checkAddOrder(productToAdd)
        } else {
          if (this.stockQuantity > 0) {
            let cartLength = 0
            let item = []
            if (localStorage.getItem('order_id_edit')) {
              cartLength = this.getCartEdit.length
              item = this.getCartEdit.find((e: any) => e.id === product.id)
            } else {
              cartLength = this.getCart.length
              item = this.getCart.find((e: any) => e.id === product.id)
            }
            if (cartLength > 0) {
              if (!item) {
                this.checkAddOrder(productToAdd)
              }
              if (item && item.quantity < this.stockQuantity) {
                this.checkAddOrder(productToAdd)
              } else if (item.quantity >= this.stockQuantity) {
                if (this.stockQuantity === 0 || this.stockQuantity === null) {
                  this.$toast(`この商品は在庫がありません。`, {
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: false,
                    rtl: false
                  })
                } else {
                  this.$toast(
                    `この商品は` + this.stockQuantity + `個のみが残ります。`,
                    {
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: false,
                      rtl: false
                    }
                  )
                }
              }
            } else {
              this.checkAddOrder(productToAdd)
            }
          } else {
            if (this.stockQuantity === 0 || this.stockQuantity === null) {
              this.$toast(`この商品は在庫がありません。`, {
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: false,
                rtl: false
              })
            } else {
              this.$toast(
                `この商品は` + this.stockQuantity + `個のみが残ります。`,
                {
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: false,
                  rtl: false
                }
              )
            }
          }
        }
      }
    }
  }

  public checkQuantityProduct(product: any) {
    if (this.getCart.length > 0) {
      const item = this.getCart.find((e: any) => e.id === product.id)

      if (item && item.quantity >= this.stockQuantity) {
        return true
      }
    }
    return false
  }

  private checkAddOrder(productToAdd: any) {
    if (localStorage.getItem('order_id_edit')) {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCartEdit(productToAdd)
    } else {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCart(productToAdd)
    }
  }
}
