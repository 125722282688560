import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class EditOrderSetting extends VuexModule {
  public order: any = { length: 0 }

  @Mutation
  public setOrder(data: any): void {
    this.order = data
  }

  @Action({ rawError: true })
  public UPDATE_ORDER(data: any): void {
    this.context.commit('setOrder', data)
  }

  get getOrder(): {} {
    return this.order
  }

}
export default EditOrderSetting