import Const from '../constants'
import axiosApiInstance from '../interceptors'

class CategoryService {
  getCategory() {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'category' + '?store_uuid=' + store_uuid)
  }
}

export default new CategoryService()
