









































import { Component, Vue } from 'vue-property-decorator'
import StoreInfoService from '@/services/StoreInfoService'
import InnerStoreModal from '@/components/InnerStoreModal.vue'
import InnerStoreMobile from '@/components/InnerStoreMobile.vue'

@Component({
  components: {
    InnerStoreMobile,
    InnerStoreModal
  }
})
export default class InnerStoreSearch extends Vue {
  public image_url: any = ''
  public inner: any = ''
  public isShowMap: boolean = false

  created() {
    StoreInfoService.getLeafletsStore().then((res: any) => {
      if (res) {
        this.inner = res.data.items
        this.image_url = res.data.items.image_url
        if (this.inner.length !== 0) {
          this.isShowMap = true
        }
      }
    })
  }

  public zoomImage() {
    this.$bvModal.show(`modal-zoom-images`)
  }
}
