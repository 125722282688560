import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class ModeCartMerge extends VuexModule {

  public modeCartMerge: boolean = false

  @Mutation
  updateModeCartMerge(payload?: any): void {
    this.modeCartMerge = payload ? payload : false
  }

  @Action({ rawError: true })
  SET_NEW_MODE_CART_MERGE(payload?: boolean): void {
    this.context.commit('updateModeCartMerge', payload);
  }

  get getModeCartMerge(): boolean {
    return this.modeCartMerge
  }

}

export default ModeCartMerge
