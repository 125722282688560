
































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalAddToCartTourMode extends Vue {
  public store_name: string = ''

  created() {
    this.store_name = String(localStorage.getItem('store_name'))
  }

  public toLogin() {
    this.$router.push({ name: 'login' })
  }
  public toSelectStore() {
    localStorage.setItem('register_mode', 'on')
    this.$router.push({ name: 'select-store' })
  }
}
