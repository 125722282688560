











































import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import OrderService from '@/services/OrderService'
const CartProductStore = namespace('CartProductStore')
const ModeBuyProduct = namespace('ModeBuyProduct')
const EditOrderSetting = namespace('EditOrderSetting')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const ModeCartMerge = namespace('ModeCartMerge')
const ModeCartEdit = namespace('ModeCartEdit')

@Component
export default class Cart extends Vue {
  public cartTotalPrice: number = 0
  public displayOverview: boolean = false
  public displayOverviewCartMode1: boolean = false
  public displayOverviewCartMode2: boolean = false
  public existOrderMergeAble: boolean = false

  private cartProductList = []
  private cartTotalQuantity: number = 0
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number
  @EditOrderSetting.Getter
  private getOrder!: any
  @EditOrderSetting.Action
  private UPDATE_ORDER!: (data: any) => any
  @ModeCartMerge.Action
  private SET_NEW_MODE_CART_MERGE!: (data: any) => any
  @ModeCartEdit.Action
  private SET_NEW_MODE_CART_EDIT!: (data: any) => any

  @Watch('getCart')
  updateCartWhenProductInCartChange() {
    this.calcOverviewCart()
    this.handleDisplayOverviewCart()
    this.checkExistOrderMergeAble()
  }

  @Watch('getCartEdit')
  updateCartWhenProductInCartEditChange() {
    this.calcOverviewCartEdit()
    this.handleDisplayOverviewCart()
  }

  @Watch('getModeBuyProduct')
  updateCartWhenModeChange() {
    this.calcOverviewCart()
    this.handleDisplayOverviewCart()
  }

  created() {
    if (this.getCartEdit.length === 0) {
      this.checkExistOrderMergeAble()
      this.calcOverviewCart()
    } else {
      this.calcOverviewCartEdit()
    }
    this.handleDisplayOverviewCart()
    if (localStorage.getItem('display_over_view')) {
      this.displayOverview = true
    }
  }

  public goToCart() {
    if (localStorage.getItem('go_to_subscription_setting-detail')) {
      this.getOrder.products = JSON.parse(
        localStorage.getItem('cart_product') || ''
      )
      this.getOrder.length = 1
      this.getOrder.number_of_products = this.getOrder.products.length
      this.UPDATE_ORDER(this.getOrder)
      localStorage.removeItem('display_over_view')
      localStorage.removeItem('cart_product')
      localStorage.removeItem('go_to_subscription_setting-detail')
      localStorage.removeItem('cart_product_edit')
      localStorage.removeItem('order_id_edit')
      localStorage.removeItem('delivery_service_id_normal_edit')
      localStorage.removeItem('order_id_merge')
      let orderId = String(localStorage.getItem('orderId'))
      this.$router.push({
        name: 'subscription-setting-detail',
        params: { order_id: orderId }
      })
    } else {
      if (localStorage.getItem('order_id_edit')) {
        let order_id = localStorage.getItem('order_id_edit')
          ? JSON.parse(JSON.stringify(localStorage.getItem('order_id_edit')))
          : ''
        this.SET_NEW_MODE_CART_EDIT(false)
        this.$router.push({
          name: 'order-edit',
          params: {
            order_id: order_id
          }
        })
      } else {
        if (localStorage.getItem('order_id_merge')) {
          let order_id = localStorage.getItem('order_id_merge')
            ? JSON.parse(JSON.stringify(localStorage.getItem('order_id_merge')))
            : ''
          this.SET_NEW_MODE_CART_MERGE(false)
          this.$router.push({
            name: 'order-merge',
            params: {
              order_id: order_id
            }
          })
        } else {
          if (this.getModeBuyProduct === 1) {
            // if response return have order before, go screen select order to merge
            if (this.existOrderMergeAble === true) {
              this.$router.push({ name: 'order-merge-select' }).catch(() => {})
            } else {
              this.$router.push({ name: 'cart' }).catch(() => {})
            }
          }
          if (this.getModeBuyProduct === 2) {
            this.$router.push({ name: 'cart' }).catch(() => {})
          }
        }
      }
    }
  }

  public calcOverviewCart() {
    this.cartProductList = this.getCart
    this.cartTotalQuantity = 0
    this.cartTotalPrice = 0

    if (this.cartProductList) {
      if (this.getModeBuyProduct === 1) {
        this.cartProductList.forEach((item: any) => {
          if (item.sale_type === 1) {
            item.mode = 1
          }
          if (item.mode === 1) {
            this.cartTotalQuantity += Number(item.quantity)
            this.cartTotalPrice += Number(
              item.quantity * item.normal_price_including_tax
            )
          }
        })
        if (this.cartTotalQuantity > 0) {
          this.displayOverviewCartMode1 = true
        }
      }

      if (this.getModeBuyProduct === 2) {
        this.cartProductList.forEach((item: any) => {
          if (item.sale_type !== 1) {
            item.mode = 2
          }
          if (item.mode === 2 && item.skip === 0) {
            this.cartTotalQuantity += Number(item.quantity)
            this.cartTotalPrice += Number(
              item.quantity * item.normal_price_including_tax
            )
          }
        })
        if (this.cartTotalQuantity > 0) {
          this.displayOverviewCartMode2 = true
        }
      }
    }
  }

  public calcOverviewCartEdit() {
    this.cartProductList = this.getCartEdit
    this.cartTotalQuantity = 0
    this.cartTotalPrice = 0
    if (this.cartProductList) {
      if (this.getModeBuyProduct === 1) {
        this.cartProductList.forEach((item: any) => {
          this.cartTotalQuantity += Number(item.quantity)
          this.cartTotalPrice += Number(
            item.quantity * item.normal_price_including_tax
          )
        })
        if (this.cartTotalQuantity > 0) {
          this.displayOverviewCartMode1 = true
        }
      }
    }
  }

  public handleDisplayOverviewCart() {
    if (this.getModeBuyProduct === 1) {
      this.displayOverview = false
      this.cartProductList.forEach((item: any) => {
        if (item.mode === 1) {
          this.displayOverview = true
        }
      })
    }

    if (this.getModeBuyProduct === 2) {
      this.displayOverview = false
      this.cartProductList.forEach((item: any) => {
        if (item.mode === 2) {
          this.displayOverview = true
        }
      })
    }
  }

  public checkExistOrderMergeAble() {
    let tour_mode = localStorage.getItem('tour_mode') ? true : false
    if (!tour_mode) {
      OrderService.getOrderMergeAble()
        .then((res: any) => {
          if (res.data.items.length > 0) {
            this.existOrderMergeAble = true
          }
        })
        .catch((err: any) => {
          if (err.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        })
    }
  }
}
