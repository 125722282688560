








































































import { Component, Vue } from 'vue-property-decorator'
import CategoryService from '@/services/CategoryService'
import { namespace } from 'vuex-class'
const Category = namespace('Category')

@Component
export default class CategoryMenu extends Vue {
  @Category.Getter
  public getCategory!: any

  private categories = ''
  @Category.Action
  private SET_CATEGORY!: (data: any) => any

  created() {
    if (localStorage.getItem('store_uuid')) {
      CategoryService.getCategory().then(
        (response: { data: { items: string } }) => {
          this.categories = response.data.items
          this.SET_CATEGORY(this.categories)
        },
        (error: {
          response: { data: any; status: any }
          message: any
          toString: () => any
        }) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          this.categories =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    }
  }

  public clearLastCurrentPaginationProduct() {
    localStorage.removeItem('lastCurrentPaginationProduct')
  }
}
