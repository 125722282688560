import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class ModeCartEdit extends VuexModule {

  public modeCartEdit: boolean = false

  @Mutation
  updateModeCartEdit(payload?: any): void {
    this.modeCartEdit = payload ? payload : false
  }

  @Action({ rawError: true })
  SET_NEW_MODE_CART_EDIT(payload?: boolean): void {
    this.context.commit('updateModeCartEdit', payload);
  }

  get getModeCartEdit(): boolean {
    return this.modeCartEdit
  }

}

export default ModeCartEdit
