





















































































import DeliveryService from '@/services/DeliveryService'
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class BtnTimeZoneDelivery extends Vue {
  public deliveryServives: any = []
  public moment = moment

  created() {
    DeliveryService.getDeliveryService().then(
      (response: { data: { items: string } }) => {
        this.deliveryServives = response.data.items
      },
      (error: {
        response: { data: any; status: any }
        message: any
        toString: () => any
      }) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
      }
    )
  }
}
