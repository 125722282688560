






















































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')

@Component
export default class Footer extends Vue {
  @Auth.Getter
  public isLoggedIn!: boolean

  created() {}
}
