import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class Product extends VuexModule {
  public productSearch: string = localStorage.getItem('product_search')
    ? JSON.parse(localStorage.getItem('product_search') || '')
    : ''

  @Mutation
  updateProductSearch(payload?: any): void {
    this.productSearch = payload
    this.productSearch = localStorage.getItem('product_search')
      ? JSON.parse(localStorage.getItem('product_search') || '')
      : ''

  }

  @Action({ rawError: true })
  SET_NEW_PRODUCT_SEARCH(payload?: any): void {
    this.context.commit('updateProductSearch', JSON.parse(JSON.stringify(payload)));
  }

  get getProductSearch(): string {
    return this.productSearch
  }

}

export default Product
