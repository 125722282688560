import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// plugin
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import VueLazyload from 'vue-lazyload'
import VueKonva from 'vue-konva'
import VueMeta from 'vue-meta'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true
})
Vue.use(VueLazyload, {
  preLoad: 1.25,
  error: 'https://ikidz.edu.vn/assets/index/img/image-default.png',
  // error: 'assets/images/image-default.png',
  loading: 'https://i.pinimg.com/originals/78/e8/26/78e826ca1b9351214dfdd5e47f7e2024.gif',
  // loading: 'assets/images/loading.gif',
  attempt: 1
})
Vue.use(VueKonva)
Vue.use(VueMeta)

import './assets/scss/index.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
