































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class InnerStoreMobile extends Vue {
  @Prop() readonly inner!: any

  public flyerImage: any = null
  public flyerData = {
    image: {
      src: '~@/assets/images/no_image_available.jpg',
      width: 1,
      height: 1
    },
    layerList: [
      {
        id: 1,
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        product_id: 12
      }
    ]
  }
  public layerList = this.flyerData.layerList

  private stageWidth = 1
  private stageHeight = 1
  private configKonva = {
    width: this.stageWidth,
    height: this.stageHeight
  }
  private scale: number = 1
  private cursor: any = 'pointer'

  created() {
    this.$nextTick(function() {
      this.fitStageIntoParentContainer()
    })

    window.addEventListener('resize', this.fitStageIntoParentContainer)

    let flyerImageInit = new window.Image()
    this.stageWidth = this.inner.image_width
    this.stageHeight = this.inner.image_height
    this.flyerData.image.src = this.inner.image_url
    this.flyerData.image.width = this.inner.image_width
    this.flyerData.image.height = this.inner.image_height
    this.layerList = this.inner.links
    flyerImageInit.src = this.flyerData.image.src
    flyerImageInit.onload = () => {
      this.flyerImage = flyerImageInit
    }
  }

  private fitStageIntoParentContainer() {
    let stageParentWidth = (this.$refs['stageParent'] as any).clientWidth
    this.scale = stageParentWidth / this.stageWidth
    this.configKonva.width = this.stageWidth * this.scale
    this.configKonva.height = this.stageHeight * this.scale
  }

  public handleClickLayer(layerList: any) {
    if (layerList.mid_category_id === 0) {
      this.$router.push({
        name: 'category-product',
        params: {
          category_id: layerList.big_category_id,
          category_name: `${layerList.name}`
        }
      })
    } else {
      if (layerList.small_category_id === 0) {
        this.$router.push({
          name: 'category-product-mid',
          params: {
            category_id: layerList.mid_category_id,
            category_name: layerList.name
          }
        })
      } else {
        this.$router.push({
          name: 'category-product-small',
          params: {
            category_id: layerList.small_category_id,
            category_name: layerList.name
          }
        })
      }
    }
  }

  public handleMouseEnter(e: any) {
    const stage = e.target.getStage()
    stage.container().style.cursor = this.cursor
  }

  public handleMouseLeave(e: any) {
    const stage = e.target.getStage()
    stage.container().style.cursor = 'default'
  }
}
