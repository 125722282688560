




import { Component, Vue } from 'vue-property-decorator'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import { namespace } from 'vuex-class'

import LayoutSettingService from '@/services/LayoutSettingService'
const Auth = namespace('Auth')

@Component({
  metaInfo: {
    meta: [
      { name: 'description', content: '明治屋ネットストアーのトップページ。ご自宅にいながら高鮮度・高品質の商品をご注文いただける「明治屋ネットストアー」は、「旬」の美味しさをご提供する生鮮品〔精肉・鮮魚・青果〕をはじめ、マイジャム類・おいしい缶詰をはじめとした信頼の明治屋オリジナルMYブランド、明治屋直輸入食品&酒類、こだわりのデリベイク明治屋ハム・ソーセージ製品、明治屋ストアー推奨品などをバラエティ豊かにご用意。当日12時までのご注文で、当日中にご自宅にお届けします。' },
    ]
  },
  components: {
    LayoutDefault
  }
})
export default class Home extends Vue {
  public layoutSettings: LayoutSetting[] = []

  @Auth.Getter
  private isLoggedIn!: boolean

  created() {
    if (!localStorage.getItem('store_uuid')) {
      this.$router.push({ name: 'store-first' })
    }
    LayoutSettingService.getSetting().then((res: any) => {
      if (res) {
        this.layoutSettings = res.data.items
      }
    })
  }

  public getComponentName(setting: LayoutSetting) {
    if (!setting.auth_req) {
      return setting.component_name
    } else {
      return this.isLoggedIn ? setting.component_name : ''
    }
  }
}
