import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from '../services/AuthService'
import router from '@/router';
import store from '@/store';

const axiosInstance = axios.create();
// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (JSON.parse(JSON.stringify(localStorage.getItem('token')) || '')) {
      config.headers = {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token') || '')}`,
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
      window.addEventListener('storage', function (event) {
        if (event.key === 'store_uuid' && !event.oldValue && event.newValue) {
          window.location.reload()
        }
      })
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  return response
}, async (error) => {

  const originalRequest = error ? error.config : null;
  if ([401].indexOf(error?.response?.status) !== -1 && !originalRequest._retry && error?.response?.data?.messages === "TOKEN_EXPIRED") {
    originalRequest._retry = true;
    await AuthService.refreshAccessToken().then(
      (res: any) => {
        localStorage.setItem('token', JSON.stringify(res))
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res;
      },
      () => {
        store.dispatch('Auth/clearLoginUser')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        router.push({ name: 'store-first' });
      })
      .catch(() => {
        store.dispatch('Auth/clearLoginUser')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        router.push({ name: 'store-first' });
      })
    return axiosInstance(originalRequest);
  }

  if ([401].indexOf(error?.response?.status) !== -1 && error?.response?.data?.messages === "INVALID_TOKEN") {
    await store.dispatch('Auth/clearLoginUser')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    await router.push({ name: 'store-first' });
  }

  return Promise.reject(error);
});

export default axiosInstance;
