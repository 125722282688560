import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { OrderPayBill } from '../../models/OrderPayBill'

@Module({ namespaced: true })
class OrderPay extends VuexModule {
  public orderPayDetail: OrderPayBill[] = []

  @Mutation
  updateOrderPayDetail(data: OrderPayBill): void {
    if (this.orderPayDetail.length === 0) {
      this.orderPayDetail.push(data)
    }
  }

  @Action({ rawError: true })
  UPDATE_ORDER_PAY(data: OrderPayBill): void {
    this.context.commit('updateOrderPayDetail', JSON.parse(JSON.stringify(data)))
  }

  get getOrderPayDetail(): OrderPayBill[] {
    return this.orderPayDetail
  }
}

export default OrderPay
