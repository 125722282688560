






















































































import { Component, Vue } from 'vue-property-decorator'
import DeliveryService from '@/services/DeliveryService'
import moment from 'moment'

@Component
export default class DeliveryServiceMenu extends Vue {
  public deliveryServives: any = []
  public moment = moment
  public tour_mode: boolean = false

  created() {
    this.tour_mode = localStorage.getItem('tour_mode') ? true : false
    this.getDeliveryService()
  }

  getDeliveryService() {
    if (!this.tour_mode) {
      DeliveryService.getDeliveryService().then(
        (response: { data: { items: string } }) => {
          this.deliveryServives = response.data.items
        },
        (error: {
          response: { data: any; status: any }
          message: any
          toString: () => any
        }) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
        }
      )
    }
  }
}
