import Const from '../constants'
import axiosApiInstance from '../interceptors'

class LayoutSettingService {
  getSetting() {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'layout/' + store_uuid)
  }
}

export default new LayoutSettingService()
