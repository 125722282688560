




























import { Component, Vue } from 'vue-property-decorator'
import NoticeService from '@/services/NoticeService'
import { namespace } from 'vuex-class'
const Auth = namespace('Auth')
const Notification = namespace('Notification')

@Component
export default class Notice extends Vue {
  @Auth.Action
  public signOut!: () => void

  @Notification.Action
  private SET_NOTIFICATION!: (data: any) => any
  @Notification.Getter
  private getNotification!: any
  private notices: any = []

  created() {
    this.fetchNotice()
  }

  private fetchNotice() {
    if (localStorage.getItem('store_uuid')) {
      if (this.getNotification.length !== 0) {
        this.notices = this.getNotification
      } else {
        NoticeService.getNotice().then(
          (response: any) => {
            this.notices = response.data.items
            this.SET_NOTIFICATION(this.notices)
          },
          (error: any) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
            this.notices =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          }
        )
      }
    }
  }

  get noticesSort() {
    this.notices = this.notices.sort(
      (a: any, b: any) =>
        new Date(b.public_date).getTime() - new Date(a.public_date).getTime()
    )
    this.notices = this.notices.slice(0, 3)
    return this.notices
  }
}
