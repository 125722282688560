var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-xl-block mb-5 mx-2"},[(!_vm.tour_mode)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-x",modifiers:{"modal-x":true}}],staticClass:"btn btn-delivery-service px-2 mb-2",attrs:{"variant":"primary"}},[_c('span',{staticClass:"d-none d-xl-block fs-xl-18"},[_vm._v("配達便の確認")])]):_vm._e(),_c('b-modal',{staticClass:"modal-delivery-service",attrs:{"id":"modal-x","size":"lg","hide-footer":"","centered":""}},[_c('h3',{staticClass:"fs-22"},[_vm._v("お届け可能な配達便")]),_c('div',{staticClass:"py-5 text-center"},[_c('b-tabs',{staticClass:"delivery-tabs"},_vm._l((_vm.deliveryServives),function(deliveryServive,index){return _c('b-tab',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"x"},[_vm._v(_vm._s(_vm.moment(index).format('YYYY年MM月DD日')))])]},proxy:true}],null,true)},[_c('div',{staticClass:"tab-content table-scroll-border"},[_c('div',{staticClass:" table-responsive mh-400 overflow-y-auto"},[_c('table',{staticClass:"table table-bordered"},_vm._l((deliveryServive),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"border-right-0 fs-xl-12 w-20-p"},[_c('div',{staticClass:"my-3"},[_vm._v(" "+_vm._s(item.service_name)+" ")])]),_c('td',{staticClass:"border-left-0 w-55-p"},[_c('div',{staticClass:"fs-xl-12 my-1"},[_vm._v(" "+_vm._s(item.start_delivery_zone)+"~ "+_vm._s(item.end_delivery_zone)+" "),_c('br'),_c('span',{staticClass:"fs-xl-12"},[_vm._v("（受付締切 "+_vm._s(item.deadline_delivery_zone)+"）")])])]),_c('td',{staticClass:"w-25-p"},[(item.passed || item.out_of_order)?_c('div',{staticClass:"my-3"},[_vm._v(" 受付終了 ")]):_vm._e(),(
                        !item.passed &&
                          !item.out_of_order &&
                          !item.exceed_half_total_order
                      )?_c('div',[_c('span',{staticClass:"text-primary-light fs-30"},[_vm._v("○")])]):_vm._e(),(
                        !item.passed &&
                          !item.out_of_order &&
                          item.exceed_half_total_order
                      )?_c('div',[_c('span',{staticClass:"text-primary-light fs-30"},[_vm._v("△")])]):_vm._e()])])}),0)])]),_c('div',{staticClass:"note"},[_c('p',{staticClass:"text-right fs-xl-12"},[_c('span',{staticClass:"text-primary-light"},[_vm._v("○")]),_vm._v("空きあり "),_c('span',{staticClass:"text-primary-light"},[_vm._v("△")]),_vm._v("残りわずか ")])])])}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }