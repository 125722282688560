














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Banner extends Vue {
  @Prop() readonly url!: string
  @Prop() readonly image!: string
  public banners: any = {}

  created() {}

  public toImage(url: any) {
    window.location.href = url
  }
}
