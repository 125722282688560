import Const from '../constants'
import axiosApiInstance from '../interceptors'

class DeliveryService {
  getDeliveryService() {
    let store_uuid = localStorage.getItem('store_uuid')
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/delivery_services', {
    })
  }
}

export default new DeliveryService()
