import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class ModeBuyProduct extends VuexModule {
  public modeBuyProduct: number = localStorage.getItem('mode_purchases')
    ? (Number(localStorage.getItem('mode_purchases')))
    : 1

  @Mutation
  updateModeBuyProduct(payload?: number): void {
    this.modeBuyProduct = payload ? payload : 1
    localStorage.setItem('mode_purchases', String(this.modeBuyProduct))
    this.modeBuyProduct = Number(localStorage.getItem('mode_purchases'))
  }

  @Action({ rawError: true })
  SET_NEW_MODE_BUY_PRODUCT(payload?: number): void {
    this.context.commit('updateModeBuyProduct', payload);
  }

  get getModeBuyProduct(): number {
    return this.modeBuyProduct
  }

}

export default ModeBuyProduct
